<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Sms Notification
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div>
      <b-alert
        show
        variant="secondary"
        class="pt-1 pl-3 pr-3 pb-1"
      >
        <div class="custom-search d-flex justify-content-between">
          <div>
            <b-form-group>
              Paid Notification SMS
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <b-form-checkbox
                v-model="paid.enable"
                value="yes"
                unchecked-value="no"
              />
            </b-form-group>
          </div>
        </div>
        <div>
          <b-row>
            <b-col>
              <b-form-group
                :state="paidSubjectState"
                invalid-feedback="Subject is required"
              >
                <b-form-input
                  v-model="paid.subject"
                  placeholder="Paid Notification SMS Subject"
                  :state="paidSubjectState"
                  :disabled="paid.enable === 'no'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :state="paidMessageState"
                invalid-feedback="Message is required"
              >
                <b-form-textarea
                  v-model="paid.message"
                  placeholder="Paid Notification SMS Message"
                  rows="3"
                  max-rows="6"
                  :state="paidMessageState"
                  :disabled="paid.enable === 'no'"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-alert>
    </div>

    <div>
      <b-alert
        show
        variant="secondary"
        class="pt-1 pl-3 pr-3 pb-1"
      >
        <div class="custom-search d-flex justify-content-between">
          <div>
            <b-form-group>
              Due Notification SMS
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <b-form-checkbox
                v-model="due.enable"
                value="yes"
                unchecked-value="no"
              />
            </b-form-group>
          </div>
        </div>
        <div>
          <b-row>
            <b-col>
              <b-form-group
                :state="dueSubjectState"
                invalid-feedback="Subject is required"
              >
                <b-form-input
                  v-model="due.subject"
                  placeholder="Due Notification SMS Subject"
                  :state="dueSubjectState"
                  :disabled="due.enable === 'no'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :state="dueMessageState"
                invalid-feedback="Message is required"
              >
                <b-form-textarea
                  v-model="due.message"
                  placeholder="Due Notification SMS Message"
                  rows="3"
                  max-rows="6"
                  :state="dueMessageState"
                  :disabled="due.enable === 'no'"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-alert>
    </div>
    <div>
      <b-button
        variant="outline-primary"

        @click="saveButton"
      >
        Save
      </b-button>
    </div>
    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BAlert,
  BFormCheckbox,
  BButton,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    BAlert,
    BFormCheckbox,
    BButton,
    BFormGroup,
    // VueGoodTable,
    // BPagination,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BLink,
    ActivityModal,
  },
  directives: {
    // 'b-modal': VBModal,
  },
  data() {
    return {
      paid: {
        enable: 'no',
        subject: null,
        message: null,
      },
      paidSubjectState: null,
      paidMessageState: null,

      due: {
        enable: 'no',
        subject: null,
        message: null,
      },
      dueSubjectState: null,
      dueMessageState: null,

      tag: 'sms-notification',
      activityModal: false,
    }
  },
  mounted() {
    this.read()
  },
  methods: {
    paidValidation() {
      this.paidSubjectState = null
      this.paidMessageState = null
      if (this.paid.enable === 'yes') {
        if (this.paid.subject === null || this.paid.subject === '') {
          this.paidSubjectState = false
        }
        if (this.paid.subject === null || this.paid.message === '') {
          this.paidMessageState = false
        }
      } else {
        this.paid.subject = null
        this.paid.message = null
      }
      if (this.paidSubjectState !== false && this.paidMessageState !== false) {
        return true
      }
      return false
    },

    dueValidation() {
      this.dueSubjectState = null
      this.dueMessageState = null
      if (this.due.enable === 'yes') {
        if (this.due.subject === null || this.due.subject === '') {
          this.dueSubjectState = false
        }
        if (this.due.subject === null || this.due.message === '') {
          this.dueMessageState = false
        }
      } else {
        this.due.subject = null
        this.due.message = null
      }
      if (this.dueSubjectState !== false && this.dueMessageState !== false) {
        return true
      }
      return false
    },

    saveButton() {
      if (!this.paidValidation()) {
        return
      }
      if (!this.dueValidation()) {
        return
      }
      const invoicePaid = {
        enable: this.paid.enable,
        subject: this.paid.subject,
        message: this.paid.message,
        type: 'invoice_paid',
      }
      const dueMsg = {
        enable: this.due.enable,
        subject: this.due.subject,
        message: this.due.message,
        type: 'due_list',
      }
      const params = [
        invoicePaid, dueMsg,
      ]

      this.$http.post(`${window.apiUrl}accounting/sms-notification`, params)
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.read()
              FemsToastrService.success(res?.data.subject)
            } else {
              FemsToastrService.error(res?.data.subject)
            }
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.subject)
        })
    },

    read() {
      this.$http
        .get(`${window.apiUrl}accounting/sms-notification/read`)
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              if (res.data.data.invoice_paid) {
                this.paid.enable = res.data.data.invoice_paid.enable
                this.paid.subject = res.data.data.invoice_paid.subject ? res.data.data.invoice_paid.subject : null
                this.paid.message = res.data.data.invoice_paid.message ? res.data.data.invoice_paid.message : null
              }

              if (res.data.data.due_list) {
                this.due.enable = res.data.data.due_list.enable
                this.due.subject = res.data.data.due_list.subject ? res.data.data.due_list.subject : null
                this.due.message = res.data.data.due_list.message ? res.data.data.due_list.message : null
              }
            }
          }
        })
    },
    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
